@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.base-drawer {
  display: flex;
  flex-direction: column;
  width: 647px;
  height: 100vh;
  // overflow: hidden;
  position: fixed;
  top: 0;
  right: -647px;
  z-index: 1000;
  overflow-x: hidden;
  transition: all 100ms linear;
  background-color: $white;
  padding: 40px 50px 0 40px;

  &--open {
    right: 0;

    .base-drawer__btn-close {
      position: fixed;
    }
  }

  &__btn-close {
    @include backgroundImage($background-size: contain);

    top: 40px;
    right: 40px;
    z-index: 1000;
    width: 20px;
    height: 20px;
    background-image: url("../../images/icons/icon-cross-gray.svg");
    cursor: pointer;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 31px;
    color: $black;
    margin-bottom: 36px;
  }

  &__banner {
    @include backgroundImage($background-size: contain);

    width: 295px;
    // height: 275px;
    height: 200px;
    flex-shrink: 0;
    margin: 0 auto 36px auto;
    background-image: url("../../images/drawer-main-banner.svg");
  }

  &__tabs-control {
    margin-bottom: 20px;
  }

  &__tabs-wrapper {
    // height: 253px;
    // overflow-y: auto;
  }

  &__content {
    overflow-y: auto;
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }
  }

  &__step {
    display: flex;
    padding: 30px 0;
    border-top: 2px solid #eeeeee;

    &-image {
      @include backgroundImage($background-size: contain);

      width: 124px;
      height: 164px;
      flex-shrink: 0;
      margin-right: 26px;

      &--1 {
        background-image: url("../../images/icons/icon-step-1.svg");
      }

      &--2 {
        background-image: url("../../images/icons/icon-step-2.svg");
      }

      &--3 {
        background-image: url("../../images/icons/icon-step-3.svg");
      }

      &--4 {
        background-image: url("../../images/icons/icon-step-4.svg");
      }

      &--5 {
        background-image: url("../../images/icons/icon-step-5.svg");
      }

      &--6 {
        background-image: url("../../images/icons/icon-step-6.svg");
      }

      &--7 {
        background-image: url("../../images/icons/icon-step-7.svg");
      }
    }

    &-box {
      margin-right: 40px;

      &--offset-top {
        padding-top: 0px;
      }
    }

    &-badge {
      display: inline-block;
      padding: 6px 12px;
      border-radius: 12px;
      background-color: #565656;
      color: $white;
      margin-bottom: 16px;

      &--blue {
        background-color: #30b6e3;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #050505;
      margin-bottom: 6px;
    }
    &-description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #565656;
      margin-bottom: 4px;
    }
    &-note {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #000000;
    }
  }
}
