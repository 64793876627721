@import "../../../scss/variables.scss";
@import "../../../scss/mixins/utils.scss";
@import "../../../scss/mixins/font.scss";

.base-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  outline: none;
  margin: 0;

  &__input {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;

    &:checked + .base-switch__slider {
      background-color: #30b6e3;

      &:hover {
        background-color: #1498c3;
      }
    }

    &:focus + .base-switch__slider {
      box-shadow: 0 0 1px #2196f3;
    }

    &:checked + .base-switch__slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d0e3ea;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:hover {
      background-color: #acc9d2;
    }

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 2px;
      bottom: 2px;
      background-color: #ffffff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
