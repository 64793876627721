@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.order-item {
  display: flex;
  padding: 40px 0;

  &:hover {
    text-decoration: none;
  }

  &--column {
    flex-direction: column;
  }

  &--in-modal {
    padding: 10px 0 20px 0;
  }

  &__image {
    @include backgroundImage($background-size: contain);

    width: 140px;
    height: 140px;
    border: 1px solid #eeeeee;
    margin-right: 24px;
  }

  &__box {
    display: flex;

    &--info {
      flex: 1;
    }
    &--payment {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-compact {
        align-items: flex-start;
        margin-top: 20px;
      }
    }

    &-row {
      display: flex;
      margin-bottom: 20px;
    }
  }

  &__date {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: $orange;
    // margin-bottom: 20px;
    margin-right: 14px;

    &:hover {
      text-decoration: none;
      color: $orange;
    }

    &--compact {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 24px;
      color: $black;

      &:hover {
        // cursor: inherit;
        color: $black;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 6px 11px;
    border-radius: 40px;
    position: relative;

    &--shift {
      bottom: 8px;
    }

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #565656;
      margin-right: 6px;
    }
  }

  &__price {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    color: $black;
    margin-bottom: 6px;

    &--discount {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-decoration: line-through;
      color: #939393;
      margin-left: 6px;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $black;
    margin-bottom: 6px;
  }

  &__size {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #939393;
  }

  &__payment {
    display: flex;
    margin-top: 8px;

    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #939393;

      &--dark {
        color: $black;
        margin-left: 3px;
      }
    }
  }

  &__btn {
    margin-bottom: 15px;
    &:hover {
      text-decoration: none;
      color: $orange;
    }
  }
}
