@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";
@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.time-line {
  margin-left: 10px;

  &__step {
    border-left: 2px solid #eeeeee;
    padding-left: 28px;
    position: relative;

    &:last-child {
      border-left: 2px solid white;
    }

    &:before {
      position: absolute;
      content: "";
      background-color: #eeeeee;
      left: -11.5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &:not(:last-child) {
      padding-bottom: 28px;
    }

    &--active {
      border-left: 2px solid $orange;

      &:before {
        background-color: $orange;
      }
    }

    &--current {
      border-left: 2px solid #eeeeee;
    }

    &-label {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $black;

      &--active {
        color: #939393;
      }
    }
    &-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #939393;
    }
  }
}
