@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";

.order-info {
  &--with-padding {
    padding: 24px 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: $black;
    margin-bottom: 24px;
  }

  &__meta {
    display: flex;
    margin-bottom: 15px;

    &-image {
      @include backgroundImage();

      width: 122px;
      height: 122px;
      margin-right: 24px;
      border-radius: 10px;
    }

    &-col {
      padding-top: 6px;
    }

    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: $color-1;
    }

    &-size {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: $color-5;
      margin-top: 6px;
    }
  }

  &__row {
    display: flex;
    min-height: 46px;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &--inline {
      display: inline-flex;
      width: 116px;
      // height: 41px;
      margin-bottom: 20px;
    }

    &--auto-height {
      min-height: auto;
      margin-bottom: 8px;
    }

    &--last {
      min-height: auto;
      margin-bottom: 0;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--fluid {
      flex: 1;
    }
    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $black;

    &--small {
      font-size: 14px;
      line-height: 17px;
      color: #565656;
    }

    &--bold {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    &--price {
      &:after {
        content: "₽";
        margin-left: 4px;
      }
    }
  }

  &__section {
    &--with-delimiter {
      &:after {
        content: "";
        display: block;
        margin: 20px 0;
        height: 1px;
        background-color: #eeeeee;
      }
    }
    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
      color: $color-1;
      margin-bottom: 8px;
    }
  }
}
