@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.header-control {
  display: flex;

  &__item {
    display: inline-flex;
    align-items: center;
    margin-left: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    color: black;

    &:hover {
      text-decoration: none;
      color: black;
    }

    &::before {
      content: "";
      display: inline-flex;
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &--basket {
      &::before {
        background-image: url("../../images/icons/icon-basket.svg");
      }
    }

    &--dropdown {
      &::before {
        background-image: url("../../images/icons/icon-door.svg");
      }
    }
  }

  &__count {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ff9501;
    font-size: 12px;
    font-weight: 500;
    color: white;
    position: absolute;
    line-height: 19px;
    top: 9px;
    left: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &-link {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      margin-bottom: 16px;
      color: #000000;
      cursor: pointer;

      &--last {
        margin-bottom: 0;
        color: #565656;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &-avatar {
      width: 47px;
      height: 47px;
      background-image: url("../../images/icons/icon-avatar-default.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-right: 18px;
    }
    &-name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 2px;
      color: black;
    }
    &-nick {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #565656;
    }
  }
}
