@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";

.product-card {
  color: $black;

  &:hover {
    color: $black;
    text-decoration: none;
    cursor: pointer;
  }

  &__image {
    @include backgroundImage();

    height: 224px;
    background-image: url("https://d1npsfkiitqw04.cloudfront.net/ThumbMedia/135089IMG-2020-07-29-08-50-43075.jpg");
  }

  &__content {
    @include flex($flex-direction: column, $justify-content: space-between);

    min-height: 112px;
    padding: 21px 19px;
  }

  &__brand {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 3px;
  }

  &__name {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  &__price {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;

    &:after {
      content: "₽";
      margin-left: 4px;
    }
  }
}
