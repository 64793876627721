@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";

.base-select {
  border: none !important;
  position: relative !important;

  * {
    cursor: pointer;
  }

  &__search-icon {
    @include backgroundImage($background-size: contain);

    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background-image: url("../../images/icons/icon-magnifier.svg");
  }
}
.mySelect__value-container {
  border: none;
  outline: none !important;

  &::before {
    @include backgroundImage($background-size: contain);

    content: "" !important;
    display: block !important;
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px !important;
    background-image: url("../../images/icons/icon-magnifier.svg") !important;
    position: relative !important;
    left: 4px !important;
  }
}

.mySelect__control {
  height: 50px !important;
  border-radius: 10px !important;
  border: 2px solid #eeeeee !important;
  outline: none !important;
}

.mySelect__value {
  border: 1px solid darkgray !important;
}
.mySelect__value-icon {
  display: none !important;
}

.mySelect__single-value {
  padding-left: 28px !important;
}

.mySelect__option {
  border-bottom: 1px solid $color-3 !important;
  padding-right: 60px !important;
  position: relative !important;
  cursor: pointer !important;

  &:after {
    width: 20px !important;
    height: 20px !important;
    content: "" !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    border-radius: 50% !important;
    border: 1px solid $color-4 !important;
    font-size: 12px !important;
    text-align: center !important;
  }

  &--is-selected {
    background-color: $color-2 !important;
    color: $color-1 !important;

    &:after {
      width: 22px !important;
      height: 22px !important;
      content: "\2713" !important;
      color: $color-2 !important;

      background-color: $color-7 !important;
      border: inherit !important;
    }
  }
}

.mySelect__placeholder {
  margin-left: 30px !important;
}
