@import "../../../../scss/variables.scss";
@import "../../../../scss/mixins/utils.scss";
@import "../../../../scss/mixins/font.scss";
@import "../../../../scss/mixins/flex.scss";

.progress-numerical {
  @include flex($align-items: center, $justify-content: space-between);

  padding: 0 0 35px 0;

  &__step {
    @include flex($align-items: center, $justify-content: space-between);

    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: $black;

    &::before {
      @include flex($align-items: center, $justify-content: center);

      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: $color-14;
      margin-right: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: $white;
    }

    &--one::before {
      content: "1";
    }

    &--two::before {
      content: "2";
    }

    &--active:before {
      background-color: $orange;
    }

    &--checked:before {
      @include backgroundImage($background-size: contain);

      content: "";
      background-image: url("../../../../images/icons/icon-check-white.svg");
      background-size: 20px 10px;
      background-color: $orange;
    }
  }

  &__delimiter {
    flex: 1;
    margin: 0 20px;
    height: 1px;
    background-color: $color-14;
  }
}
