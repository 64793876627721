@import "../../scss/variables.scss";
@import "../../scss/mixins/flex.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.card-banner {
  @include flex($align-items: stretch);

  min-height: 100px;
  padding: 16px 24px;
  border-radius: 10px;
  position: relative;

  &--green {
    background-color: $color-9;
  }

  &--blue {
    background-color: $color-10;
  }

  &__content {
    @include flex($justify-content: space-between);
    flex: 1;

    &--slot-right {
      @include flex($flex-direction: row, $flex-wrap: nowrap);
    }
  }

  &__coll {
    @include flex($flex-direction: column, $justify-content: space-between);

    height: 100%;

    &--left {
      align-items: flex-start;
    }

    &--right {
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 5px;
    }
  }

  &__icon {
    @include backgroundImage($background-size: contain);

    flex-shrink: 0;
    margin-right: 13px;

    &--safe-deal {
      width: 22px;
      height: 24px;
      background-image: url("../../images/icons/icon-safe-deal.svg");
    }

    &--authenticity-of-goods {
      width: 60px;
      height: 57px;
      background-image: url("../../images/icons/icon-authenticity-of-goods.svg");
    }
  }

  &__title {
    @include flex($align-items: center);

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    // margin-top: 5px;

    .icon-info {
      margin-left: 6px;
    }
  }

  &__tooltip {
    @include backgroundImage($background-size: contain);

    width: 19px;
    height: 19px;
    background-image: url("../../images/icons/icon-info-orange.svg");
    margin-left: 7px;
    cursor: pointer;
  }

  &__slot {
    @include flex($align-items: center);

    line-height: 1;
    margin: 10px 0;
    color: $color-11;
  }

  &__price {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 9px;
    // margin-top: 10px;
    white-space: nowrap;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 5px 0 8px 0;
    // margin-top: 6px;

    &--has-children {
      margin: 0;
    }
  }

  &__link {
    @include flex($align-items: center);

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $color-7;
    cursor: pointer;
    // margin-top: 8px;

    &:after {
      @include backgroundImage($background-size: contain);

      content: "";
      display: block;
      width: 11px;
      height: 14px;
      margin-left: 4px;
      margin-top: 1px;
      background-image: url("../../images/icons/icon-arrow-orange.svg");
    }

    &--no-arrow {
      &:after {
        background-image: inherit;
      }
    }

    &:hover {
      color: $color-7;
      text-decoration: none;
    }
  }
}
