.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-pointer {
  cursor: pointer;
}

#bottom-button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 995;
}
#bottom-button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
#bottom-button:hover {
  cursor: pointer;
  background-color: #333;
  text-decoration: none;
  color: #fff;
}
#bottom-button:active {
  background-color: #555;
}
#bottom-button.show {
  opacity: 1;
  visibility: visible;
}
.cursor-pointer {
  cursor: pointer;
}
.app-shadow {
  width: 100%;
  height: 100vh;
  background-color: rgba(21, 30, 48, 0.7);
  position: fixed;
  z-index: 999;
}

.scroll-disabled {
  overflow: hidden;
}

#basic-nav-dropdown + .dropdown-menu.show {
  transform: inherit !important;
}

/* .currency-field {
  display: inline-block;
  background-image: url("shared/images/icons/icon-ruble.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: inherit;
  width: 15px;
} */
