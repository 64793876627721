.app-alert-1 {
  display: none;
  box-shadow: 0px 1.8674px 23.3425px rgba(0, 0, 0, 0.1);

  @media (max-width: 567px) {
    display: block;
  }

  &.alert-light {
    &.show {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1060;
    }
  }

  .app-dialog {
    display: flex;
    align-items: center;

    .app-title {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      margin: 0 9px;
      word-break: break-all;
    }

    .btn-store {
      border: 1px solid #000;
      color: #000;
      font-weight: 500;
      padding: 5px 10px;
      border-radius: 7px;
      font-size: 14px;
    }
  }
}

header.navbar {
  display: block;
  background: #ffffff;
  padding: 0;
  //   margin-bottom: 45px;

  &--with-offset {
    margin-bottom: 45px;
  }

  @media (max-width: 567px) {
    padding: 0 15px;
  }

  & > .container {
    flex-wrap: wrap;

    // &.navbar-border {
    //   border-bottom: 3px solid #e5e5e5;
    // }
  }

  .navbar-brand {
    width: 100%;
    padding: 30px 0 20px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1;

    @media (max-width: 991px) {
      width: auto;
      padding: 15px 0;
    }

    img {
      @media (max-width: 991px) {
        width: 200px;
      }
    }
  }

  .navbar-toggler {
    @media (max-width: 991px) {
      padding: 0;
      border-radius: 0;
      border: 0;
      position: relative;
      width: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 20px;
      outline: 0;
      box-shadow: none;
    }

    span {
      @media (max-width: 991px) {
        display: block;
        width: 100%;
        height: 3px;
        background: #ff9501;
        border-radius: 3px;
      }
    }

    &::before,
    &::after {
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        background: #ff9501;
        border-radius: 3px;
        height: 3px;
      }
    }

    &::after {
      @media (max-width: 991px) {
        top: auto;
        bottom: 0;
      }
    }
  }

  ul.navbar-nav {
    .nav-item {
      margin-right: 25px;

      @media (max-width: 991px) {
        margin: 0;
      }

      a {
        padding: 10px 0px;
        font-size: 16px;
        color: #000000;
        position: relative;
        text-decoration: none;
        line-height: 20px;
        font-weight: normal;
        display: inline-block;

        @media (max-width: 991px) {
          padding: 10px;
          display: block;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 0;
          margin: auto;
          height: 3px;
          background: #ffb040;
          transition: all 0.5s;
        }

        &.active,
        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  #navbarNav {
    position: static;

    .navbar-nav {
      position: static;

      .nav-item {
        position: static;

        .dropdown-toggle::after {
          display: none;
        }

        .dropdown-menu {
          width: 100%;
          border: none;
          padding: 20px 5%;
          border-radius: 0;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          top: calc(100% - 15px) !important;

          @media only screen and (max-width: 991px) {
            position: relative !important;
            top: auto !important;
            transform: none !important;
          }

          h5 {
            font-size: 18px;
            color: #ababab;
          }

          .linklist {
            -moz-column-count: 5;
            column-count: 5;
            -moz-column-gap: 1.875rem;
            column-gap: 1.875rem;
            margin: 0;
            padding: 0;
            list-style: none;

            @media only screen and (max-width: 1024px) {
              -moz-column-count: 3;
              column-count: 3;
              -moz-column-gap: 15px;
              column-gap: 15px;
            }

            @media only screen and (max-width: 767px) {
              -moz-column-count: 1;
              column-count: 1;
              -moz-column-gap: 0;
              column-gap: 0;
            }

            .dropdown-item {
              padding: 6px 0;
              width: auto;

              &:hover {
                background: transparent;
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .user-navbar {
    .nav-item {
      margin-right: 0 !important;
    }

    .user-login.dropdown {
      display: block;

      .dropdown-toggle.btn.btn-primary {
        background: url(../../images/arrowdown.png) right 10px center no-repeat;
        padding: 21px 30px;
        border: 0;
        border-radius: 0;
        color: #000000;
        font-size: 18px;
        line-height: 1;
        outline: 0;
        box-shadow: none;

        @media (max-width: 991px) {
          padding: 20px;
          display: block;
          width: 100%;
          text-align: left;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        overflow: hidden;

        .dropdown-item {
          padding: 15px;
          font-size: 14px;
          color: #000000;
          line-height: 1;
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    &__link {
      padding: 10px 0px;
      font-size: 16px;
      color: #000000;
      position: relative;
      text-decoration: none;
      line-height: 20px;
      font-weight: normal;
      display: inline-block;
      // font-weight: bold;
    }
  }
}

.navbar-nav--with-border {
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #f5f5f5;
  }
}

.header-login {
  cursor: pointer;
}
