@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.base-alert {
  padding: 18px 51px 18px 21px;
  background-color: #fafafa;
  border-radius: 10px;
  position: relative;

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 6px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: $color-5;
  }

  &__btn-close {
    @include backgroundImage($background-size: contain);

    position: absolute;
    top: 16px;
    right: 16px;
    width: 10px;
    height: 10px;
    background-image: url("../../images/icons/icon-cross-gray.svg");
    cursor: pointer;
  }

  // &--warning {

  // }
}
