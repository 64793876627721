.logo-bg {
  display: block;
  background: #ffffff;
  padding: 0;

  .container {
    flex-wrap: wrap;
  }

  .navbar-brand {
    width: 100%;
    padding: 30px 0 20px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1;
    background-color: red;

    @media (max-width: 567px) {
      width: 60%;
      margin: 0 auto;
      padding: 15px 0;
    }

    img {
      max-width: 100%;
    }
  }
}
