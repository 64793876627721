.base-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &--inline {
    display: inline-block;
    height: auto;
  }
}
