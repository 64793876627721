@import "../../scss/variables.scss";
@import "../../scss/mixins//flex.scss";

.button {
  @include flex($justify-content: center, $align-items: center);

  height: 50px;
  display: inline-flex;
  padding: 0 30px;
  font-weight: 700;
  text-decoration: none;
  transition: color 100ms linear, background-color 100ms linear;
  appearance: none;
  cursor: pointer;
  outline: none;
  border-radius: 40px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border: inherit;
  text-transform: uppercase;

  &--block {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &--primary {
    color: #ffffff;
    background-color: #ff9501;

    &:hover {
      background-color: #ea8c0a;
    }

    &:active {
      background-color: #dd850a;
    }
  }

  &--secondary {
    color: #ff9501;
    background-color: #f2f2f2;

    &:hover {
      background-color: #f3ede5;
    }

    &:active {
      background-color: #e8dbcb;
    }
  }

  &__content {
    white-space: nowrap;
  }
}
