@import "../../scss/variables.scss";
@import "../../scss/mixins//flex.scss";

.button-group {
  @include flex($justify-content: space-between);

  width: 100%;

  &--reverse {
    flex-direction: row-reverse;
  }

  &--vertical-align-center {
    @include flex($justify-content: space-between, $align-items: center);
  }

  &__item {
    width: calc((100% - 1rem) / 2);

    &--full-width {
      width: 100%;
    }

    &--short-width {
      width: auto;
      margin-left: 1rem;
    }
  }
}
