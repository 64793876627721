@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/variables.scss";

.base-list {
  &__head {
    padding: 0 20px 0 80px;
    border-bottom: 2px solid #eeeeee;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    color: #959595;
  }
  &__body {
    height: 440px;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #565656;
  }
  &__row {
    display: flex;

    &--with-hover {
      cursor: pointer;
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 82px;
    flex-shrink: 0;

    &--low {
      height: 48px;
    }

    &--text-dark {
      color: #000000;
    }

    &--with-border {
      border-bottom: 2px solid #fafafa;
    }
  }
  &__address {
    padding-right: 50px;
  }
  &__checkbox {
    flex: 0;
    flex-basis: 80px;
    padding: 0 24px 0 10px;
    display: flex;

    &:after {
      content: "";
      margin: auto;
      width: 24px;
      height: 24px;
      background-color: $white;
      box-shadow: 0px 0px 0px 2px #dddddd;
      border-radius: 50%;
    }

    &--active {
      &:after {
        background-color: $orange;
        border: 4px solid $white;
      }
    }
  }
}
