.app-alert {
    display: none;
    box-shadow: 0px 1.8674px 23.3425px rgba(0, 0, 0, 0.1);

    @media (max-width:567px) {
        display: block;
    }

    .app-dialog {
        display: flex;
        align-items: center;

        .app-title {
            color: #000;
            font-weight: 500;
            font-size: 11px;
            margin: 0 9px;
            word-break: word-break;
        }

        .btn-store {
            border: 1px solid #000;
            color: #000;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 7px;
            font-size: 14px;
        }
    }

}