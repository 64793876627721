@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  background: #fff !important;
  font-family: "Montserrat", Sans-serif !important;
  color:#000 !important;
}
.border-btn {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  padding: 16px 36px;
}
.border-btn:hover {
  background: #FF9500;
  color: #fff;
  border-color: #FF9500;
}
.border-btn:focus {
  outline: none;
}
.seperator hr {
  border-top: 1px solid #EAEAEA;
  max-width: 840px;
  margin: 20px auto;
}

hr.divider {
  margin: 0;
  border-top: 3px solid #F5F5F5;
  margin: 3px 0 52px 0;
}
.form-group label{
  display: block;
  margin: 0 0 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.form-group .form-control {
  padding: 11px 15px 12px 15px;
  margin: 0;
  border-radius: 0;
  background-color: #fff;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #C2C2C2;
  box-sizing: border-box;
  min-height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #C2C2C2;
}
.form-group .css-yk16xz-control {
  min-height: 40px;
  border-radius: 0;
}
.form-group .form-control:focus {
  box-shadow: none;
}
.form-error {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #F63131;
  margin: 4px 0 0 0;
}
