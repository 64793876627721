@import "../../scss/variables.scss";
@import "../../scss/mixins//flex.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.base-error {
  @include flex($align-items: center);
  @include set-font(null, 14px, null, 500);

  min-height: 24px;
  position: relative;
  padding-left: 26px;

  &--no-icon {
    padding-left: 0;
  }

  &--danger {
    color: $color-6 !important;
  }

  // &--warning {
  // color: $color-21;
  // }

  // &--info {
  // color: $dark-blue;
  // }

  &--description {
    color: $color-5;
  }

  &__icon {
    @include backgroundImage($background-size: contain);

    position: absolute;
    top: 2px;
    left: 0;

    display: inline-block;
    min-width: 1.2rem;
    font-size: 19px;
    height: 19px;
    margin-right: 10px;

    &--danger {
      background-image: url("../../images/icons/icon-error-danger.svg");
    }

    // &--warning {
    // background-image: url("../../../shared/images/icons/icon-warning-triangle.svg");
    // }

    // &--info {
    // background-image: url("../../../shared/images/icons/icon-info-triangle.svg");
    // }

    // &--description {
    //   display: none;
    // }
  }
}
