@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.base-rating {
  display: flex;

  &--in-modal {
    margin: 30px 0;
  }
  &__icon {
    width: 52px;
    height: 52px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/icons/icon-star-gray.svg");
    margin-right: 8px;
    cursor: pointer;

    &--active {
      background-image: url("../../images/icons/icon-star-orange.svg");
    }
  }
}
