footer {
  display: block;
  position: relative;
  border-top: solid 1px #e0e0e0;
  padding: 65px 0;
  margin-top: 50px;
  background: #f9f9f9;

  @media (max-width: 567px) {
    padding: 25px 0;
  }

  .footer-left {
    padding: 0 70px 0 55px;

    @media (max-width: 991px) {
      padding: 0 50px 0 0;
    }

    @media (max-width: 567px) {
      padding: 0 0 20px;
    }

    .image {
      width: 175px;
      margin: 0 0 25px 12px;

      img {
        max-width: 100%;
        display: block;
      }
    }

    p {
      font-size: 16px;
      color: #888888;
      line-height: 23px;
      letter-spacing: 0.32px;
      font-weight: 400;
      margin: 0 0 16px;
    }

    ul.app-icon {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 35px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 0.5rem;

        a {
          color: #222222;
          font-size: 16px;
          text-decoration: none;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .footer-right {
    padding: 0 0 0 70px;

    @media (max-width: 991px) {
      padding: 0;
    }

    h4 {
      display: block;
      font-size: 18px;
      color: #222222;
      margin: 0;
      line-height: 28px;
      letter-spacing: 0.36px;
      font-weight: 500;
    }

    p {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 0 5px;
      font-size: 16px;
      color: #888888;

      i {
        margin-right: 10px;
        margin-top: 4px;
        color: #222222;
        font-size: 18px;
      }

      a {
        color: #888888;
      }
    }
  }
}
