@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/variables.scss";

.base-radio {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    width: 310px;
    height: 100px;
    display: flex;
    padding: 0 30px 0 60px;
    background-color: $color-8;
    border-radius: 10px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--fluid {
      flex: 1;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: $black;
    margin-bottom: 5px;
    position: relative;

    &:before {
      @include backgroundImage($background-size: contain);

      content: "";
      display: inline-block;
      width: 22px;
      height: 22px;
      position: absolute;
      top: -1px;
      left: -32px;
    }

    &--truck {
      &:before {
        background-image: url("../../images/icons/icon-truck.svg");
      }
    }

    &--shop {
      &:before {
        background-image: url("../../images/icons/icon-shop.svg");
      }
    }
  }

  &__price {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: $black;

    &:after {
      content: "₽";
      margin-left: 4px;
    }
  }

  &__circle {
    width: 30px;
    height: 30px;
    background-color: $white;
    box-shadow: 0px 0px 0px 2px #dddddd;
    border-radius: 50%;

    &--active {
      background-color: $orange;
      border: 4px solid $white;
    }
  }
}
