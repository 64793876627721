@mixin flex(
  $align-items: flex-start,
  $justify-content: flex-start,
  $flex-wrap: nowrap,
  $flex-direction: row,
  $inline: false
) {
  @if ($inline == true) {
    display: inline-flex;
  } @else {
    display: flex;
  }

  align-items: $align-items;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  justify-content: $justify-content;
}
