$color-1: #000000;
$color-2: #ffffff;
$color-3: #f8f8f8;
$color-4: #959595;
$color-5: #565656;
$color-6: #ff2358;
$color-7: #ff9501;
$color-8: #f2f2f2;
$color-9: rgba(183, 255, 238, 0.5);
$color-10: #e7f6fb;
$color-11: rgba(5, 5, 5, 0.4);
$color-12: #d8e5e9;
$color-13: #e5f3f8;
$color-14: #c2c2c2;

$white: #ffffff;
$black: #000000;

$gray-light: #fafafa;
$gray: #959595;
$gray-dark: #565656;

$orange: #ff9501;
$red: #ff2358;
