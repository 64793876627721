@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";

.product-info {
  flex: 1;

  &__code {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 8px;
    color: rgba(102, 102, 102, 1);
  }

  &__row {
    @include flex($flex-wrap: wrap);

    &--with-dash {
      margin-bottom: 20px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 47px;
        height: 3px;
        background-color: $orange;
      }
    }

    &--with-price {
      align-items: flex-end;
      margin-bottom: 26px;
    }

    &--offset-top {
      margin-top: 20px;
    }
  }

  &__category {
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    white-space: nowrap;
    margin-right: 15px;

    &--small {
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
    }
  }
  &__name {
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
    margin-bottom: 19px;

    &--small {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 16px;
    }
  }

  &__price {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    margin-right: 10px;
  }

  &__discount {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: line-through;
    color: $gray;
  }

  &__table {
    border-collapse: separate;
    width: calc(100% - 90px);

    &--full-width {
      width: 100%;
    }
  }

  &__table-row {
    vertical-align: top;
    margin-bottom: 20px;
  }

  &__table-cell {
    width: 50%;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #666666;

    &--bold {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: $black;
    }

    &--bottom-offset {
      padding-bottom: 20px;
    }

    .icon-info {
      margin-left: 4px;
    }
  }

  &__delimiter {
    width: calc(100% - 90px);
    height: 1px;
    background-color: #eeeeee;
    margin: 8px 0;

    &--full-width {
      width: 100%;
    }
  }

  &__brand-logo {
    margin: 25px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  &__button {
    padding: 24px 0 16px 0;
  }

  &__link {
    display: flex;
    justify-content: center;
  }

  &__link-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: $orange;

    &--bold {
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
