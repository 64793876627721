@import "../variables.scss";

.input {
  &__header {
    padding: 0 10px 10px 10px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: $color-4;

    &--no-left-offset {
      padding: 0 10px 10px 0;
    }

    &--disabled {
      padding: 0 10px 5px 10px;
    }
  }

  &__field {
    width: 100%;
    height: 50px;
    padding-left: 14px;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    color: $color-1;
    background: $color-3;

    border: 2px solid $color-3;
    border-radius: 10px;

    outline: none;
    cursor: pointer;

    &::placeholder {
      color: $color-4;
    }

    &--has-value {
      background: $color-2;
    }

    &--error {
      border-color: $color-6;
    }

    &--phone {
      padding-left: 25px;
    }

    &--code {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 6px;
    }

    &--textarea {
      height: 150px;
      padding: 14px;
      resize: none;
    }

    &--disabled {
      height: 25px;
      border: none;
      cursor: inherit;
      background-color: transparent;
    }
  }

  &__phone-wrapper {
    position: relative;

    &::after {
      position: absolute;
      top: 15px;
      left: 12px;
      content: "+";
      font-size: 22px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(149, 149, 149, 1);
    }

    &--disabled {
      &::after {
        // content: "-";
        top: 2px;
        left: 11px;
      }
    }
  }

  &__slot {
    padding: 10px 0 0 10px;
  }

  &__footer {
    padding: 10px 0 0 10px;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: $color-5;
  }
}
