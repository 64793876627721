@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.base-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  &--compact {
    display: inline-flex;
    border-radius: 10px;
    background-color: #f2f2f2;
  }

  &__label {
    border: 1px solid #959595;
    border-radius: 70px;
    color: #000000;
    cursor: pointer;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    box-sizing: border-box;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      border-color: #ff9501;
    }

    &--active {
      border: 1px solid transparent;
      background-color: #fff2e1;
      color: #ff9501;
      border: inherit;

      &:hover {
        background-color: #efddc6;
      }
    }

    &--fluid {
      flex: 1;
      text-align: center;
    }

    &--compact {
      padding: 13px 23px;
      border-radius: 10px;
      border: inherit;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: #565656;

      &:not(:last-child) {
        margin-right: 0;
      }

      &.base-tabs__label--active {
        color: $orange;
        background-color: #f8f8f8;
      }
    }
  }

  &__wrapper {
    padding: 0;
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }
  }
}
