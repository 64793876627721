@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";

.popover {
  padding: 24px 20px;
  max-width: 381px;

  &-header {
    background-color: white;
    border-bottom: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 18px;
    color: rgba(0, 0, 0, 1);
  }
  &-body {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(102, 102, 102, 1);
  }
  &-info {
    padding: 20px;
    background-color: rgba(231, 246, 251, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 16px;
  }
}

.overlay-wrapper {
  display: inline-block;
}
