@import "../../../scss/variables.scss";
@import "../../../scss/mixins/utils.scss";
@import "../../../scss/mixins/font.scss";
@import "../../../scss/mixins/flex.scss";

.icon-info {
  display: inline-block;
  color: #959595;
  cursor: pointer;

  &:hover {
    color: #666666;
  }

  &--orange {
    color: #ff9501;

    &:hover {
      color: #e18300;
    }
  }
}
