@import "../../scss/variables.scss";
@import "../../scss/mixins/utils.scss";
@import "../../scss/mixins/font.scss";
@import "../../scss/mixins/flex.scss";

.base {
  &-modal {
    &.modal-dialog {
      max-width: 100%;
    }

    width: 563px !important;

    &--wide {
      width: 720px !important;
    }

    &__icon-close {
      @include backgroundImage($background-size: contain);

      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1000;
      width: 20px;
      height: 20px;
      background-image: url("../../images/icons/icon-cross-gray.svg");
      cursor: pointer;
    }

    &__body {
      padding: 50px;
    }

    &__row {
      @include flex();

      &--h-center {
        justify-content: center;
      }

      &--v-center {
        align-items: center;
      }

      &--with-offset {
        padding: 30px 0;
      }
    }

    &__content {
      &--offset-right {
        margin-right: 100px;
      }
    }

    &__input {
      margin-bottom: 20px;
    }

    &__image {
      @include backgroundImage($background-size: contain);

      flex-shrink: 0;

      &--product {
        width: 182px;
        height: 208px;
        border: 1px solid #cecece;
        margin-right: 30px;
      }

      &--know-availability {
        width: 175px;
        height: 250px;
        margin-right: 59px;
        margin-left: 30px;
        background-image: url("../../images/already-know-availability.svg");
      }

      &--something-went-wrong {
        width: 170px;
        height: 158px;
        margin-right: 35px;
        background-image: url("../../images/something-went-wrong.svg");
      }
    }

    &__title {
      font-size: 22px;
      font-weight: 600;
      line-height: 31px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 20px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $gray;
      margin-bottom: 20px;

      &--without-bottom-offset {
        margin-bottom: 0;
      }
    }

    &__link {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $orange;
      cursor: pointer;
    }

    &__info {
      &-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 16px;
      }
      &-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &--email {
          margin-top: 10px;
          color: rgba(147, 147, 147, 1);
        }
      }
      &-delimiter {
        margin-bottom: 15px;
      }
    }
    &__footer {
      display: flex;
      &--align-center {
        justify-content: center;
      }
    }
  }
}
